'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { useMemo, useRef } from 'react';

import { Heading, Paragraph } from '@/components/dom/text-elements';
import { AddEditResearchItemForm } from '@/components/forms/add-edit-research-item';
import Icon from '@/components/global/icon';
import { ActionLink, NavigationLink } from '@/components/global/link';
import { useLoggedInGate } from '@/components/providers/LoggedInGate';
import { ResearchSidebarState, useResearch } from '@/components/providers/Research';
import { ResearchDetailView } from '@/components/research/item-detail-view';
import { UserResearchItemGroup } from '@/components/research/users-research-item-group';
import HighlightedText from '@/components/ui/HighlightedText';
import IconButton from '@/components/ui/IconButton';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import { constructTeamPageUrl } from '@/helpers/organization';
import { groupResearchNotesByUser } from '@/helpers/research';
import { getResearchNoteCategory, getResearchNoteTitleAndPageName } from '@/helpers/research';
import cn from '@/lib/cn';
import { RESEARCH_NOTE_ID_QUERY_PARAM } from '@/lib/constants/research';
import { TeamViews } from '@/lib/constants/team';
import { type IndexBasicInfoType } from '@/services/indices';
import { type BaseCompany } from '@/types/company';

interface ResearchSidebarProps {
    className?: string;
}

const AllResearchItemsView = () => {
    const { currentUser } = useLoggedInGate();
    const { researchNotes } = useResearch();
    const groupedNotes = useMemo(() => {
        const teamMembers = currentUser?.primaryOrganization?.organizationUsers || [];
        return groupResearchNotesByUser({
            researchNotes: researchNotes || [],
            teamMembers,
        });
    }, [researchNotes, currentUser]);

    return (
        <div>
            <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-10 overflow-y-auto no-scrollbar">
                    {Object.values(groupedNotes).map(({ name, notes }, index: number) => {
                        const isEmpty = notes.length === 0;

                        return (
                            !isEmpty && (
                                <UserResearchItemGroup
                                    key={`user-research-group-${index}-${name}`}
                                    researchNotes={notes}
                                    name={name}
                                    className="max-h-none"
                                />
                            )
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const SidebarViewMap = {
    [ResearchSidebarState.VIEW_ALL]: AllResearchItemsView,
    [ResearchSidebarState.DETAIL_VIEW]: ResearchDetailView,
    [ResearchSidebarState.ADD_EDIT]: AddEditResearchItemForm,
};

export const ResearchSidebar = ({ className }: ResearchSidebarProps) => {
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const sidebarRef = useRef<HTMLElement | null>(null);
    const { currentUser } = useLoggedInGate();
    const { eventTypes, trackManualEvent } = usePosthogTracking();
    const {
        companyInfo,
        indexInfo,
        researchAnalysisInfo,
        isResearchSidebarOpen,
        hideResearchSidebar,
        researchNotes,
        sidebarMode,
        switchToViewAllMode,
    } = useResearch();
    const SidebarView = SidebarViewMap[sidebarMode];
    const isViewAllMode = sidebarMode === ResearchSidebarState.VIEW_ALL;
    const isResearchPage = pathname?.includes('/research') ?? false;
    const isEvaluationPage = pathname?.includes('/evaluation') ?? false;
    const category = getResearchNoteCategory({
        analystRequestId: researchAnalysisInfo?.requestId,
        indexId: indexInfo?.id,
        instrumentId: companyInfo?.id,
    });
    const { pageName } = getResearchNoteTitleAndPageName({
        category,
        company: companyInfo as BaseCompany,
        indexName: (indexInfo as IndexBasicInfoType)?.name,
        isEvaluation: isEvaluationPage,
        symbol: (indexInfo as IndexBasicInfoType)?.symbol,
        theme: researchAnalysisInfo?.theme as string,
    });
    const onViewAllClick = () => {
        trackManualEvent({
            eventType: eventTypes.VIEW_ALL_RESEARCH_ITEMS,
            trackingProperties: {
                iconType: 'arrow',
                label: 'Back to list',
            },
        });
        switchToViewAllMode();

        // If user is viewing a research item with a query param, remove it from the URL
        const queryString = searchParams?.toString();
        const params = new URLSearchParams(queryString);
        const hasResearchNoteIdQueryParam = params?.has(RESEARCH_NOTE_ID_QUERY_PARAM);

        if (hasResearchNoteIdQueryParam) {
            params.delete(RESEARCH_NOTE_ID_QUERY_PARAM);
        }

        window.history.replaceState(
            { ...window.history.state, as: pathname, url: pathname },
            '',
            `${pathname}?${params.toString()}`
        );
    };

    return (
        <aside
            ref={sidebarRef}
            className={cn(
                'fixed transition-all bg-white py-8 pl-6 pr-8 top-1/2 right-0 -translate-y-1/2 w-full max-w-[95vw] lg:max-w-[35vw] h-screen shadow-xl border-l border-analyst-dark-lavender overflow-y-scroll no-scrollbar',
                {
                    '-z-10 opacity-0 translate-x-full transition-all': !isResearchSidebarOpen,
                    'transition-all z-2000 opacity-100 transform-x-0': isResearchSidebarOpen,
                },
                className
            )}
        >
            <header className="flex items-center justify-between mb-6">
                <Heading importance={3}>
                    <HighlightedText>Team Research</HighlightedText>
                </Heading>
                <IconButton
                    iconType="closeX"
                    onClick={hideResearchSidebar}
                    className="group"
                    iconProps={{
                        iconClassName: 'group-hover:text-analyst-dark-lavender transition-colors',
                        size: 'lg',
                    }}
                    tracking={{
                        eventType: eventTypes.CLOSE_RESEARCH_SIDEBAR_NAV,
                    }}
                />
            </header>
            {isViewAllMode && (
                <>
                    {!isResearchPage ? (
                        <Paragraph className="font-brand-bold text-xl mb-10 break-words">{pageName}</Paragraph>
                    ) : (
                        <Paragraph className="font-brand-md mb-10">
                            Save modules, company pages, indices, or research results to your research for later
                            reference.
                        </Paragraph>
                    )}

                    {currentUser?.primaryOrganization && !isResearchPage && (
                        <NavigationLink
                            href={constructTeamPageUrl({
                                organizationId: currentUser.primaryOrganization.id,
                                teamView: TeamViews.RESEARCH,
                            })}
                            className="mb-4 font-brand-md flex items-center gap-2"
                            target="_blank"
                        >
                            View all Team Research
                            <Icon
                                type="arrow"
                                size="xs"
                                className="text-analyst-blue transition-colors"
                            />
                        </NavigationLink>
                    )}
                </>
            )}

            {!isViewAllMode && (
                <ActionLink
                    onClick={onViewAllClick}
                    className="flex items-center gap-2 mb-6 font-brand-md hover:cursor-pointer group"
                >
                    <Icon
                        type="arrow"
                        size="xs"
                        className="text-analyst-blue rotate-180 group-hover:text-analyst-dark-lavender transition-colors"
                    />
                    Back to list
                </ActionLink>
            )}

            {!researchNotes.length && isViewAllMode ? (
                <Paragraph className="font-brand-bold mt-20 text-lg">
                    There is currently no saved research for this page.
                </Paragraph>
            ) : (
                <SidebarView />
            )}
        </aside>
    );
};
