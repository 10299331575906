import styled from '@emotion/styled';

interface PulseWrapperProps {
    children: React.ReactNode;
    borderWidth?: number;
    removePulse?: boolean;
    className?: string;
}

interface PulseAnimationWrapperProps {
    $borderWidth?: number;
}

const PulseAnimationWrapper = styled.div<PulseAnimationWrapperProps>`
    ${({ $borderWidth = 20 }) =>
        `
       @keyframes borderAnimation {
            0% {
                background-color: rgba(51,204,255, 0.4);
                box-shadow: 0 0 0 0 rgba(51,204,255, 0.4);
            }

            80% {
                background-color: rgba(68,61,255, 0);
                box-shadow: 0 0 0 ${$borderWidth}px rgba(68,61,255, 0);
            }

            100% {
                background-color: rgba(68,61,255, 0);
                box-shadow: 0 0 0 0 rgba(68,61,255, 0);
            }
        }

        animation: borderAnimation 2s infinite;
    `}
`;

export const PulseWrapper: React.FC<PulseWrapperProps> = ({
    className,
    children,
    borderWidth = 20,
    removePulse = false,
}) => {
    return removePulse ? (
        children
    ) : (
        <PulseAnimationWrapper
            className={className}
            $borderWidth={borderWidth}
        >
            {children}
        </PulseAnimationWrapper>
    );
};
